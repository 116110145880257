<template>
  <div class="program">

    <div class="chosen px-3">
      <div class="text-sm font-weight-bold h5 mb-4 mt-2">
        <!-- <div class="mb-2 px-4">
          <div class="font-weight-bold">
            العدد الكلي
            <span class="mx-1 text-success font-weight-normal" v-if="program.tracks">{{ program.tracks.length }}</span>
          </div>
        </div>
        <div class="btn btn-success font-weight-bold rounded-md" v-if="program.tracks && program.tracks.length">
          <i class="la la-play me-2"></i> تشغيل الكل
        </div> -->
        <!-- <div class="text-center float-end font-weight-normal">
          <small class="font-weight-bold mb-1 d-block">المدة بالكامل</small>
          <small class="text-success d-block">13:01:33</small>
        </div> -->
      </div>
      <div class="row mx-0">

        <HorizontalCardLoader v-if="$apollo.queries.tracks.loading" />

        <div class="col-md-12" v-for="track in tracks.data ?? []" :key="track.id">
          <TrackHorizontalCard :track="track" :tracklist="tracks.data" />
        </div>

        <div class="col-md-12 text-center" v-if="!$apollo.queries.tracks.loading && !tracks.data.length">
          <div class="main-card p-5">
            لا توجد صوتيات هنا!
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrackHorizontalCard from "../../components/TrackHorizontalCard.vue";
import gql from 'graphql-tag'
import HorizontalCardLoader from '../../components/Loader/HorizontalCardLoader.vue';

export default {
  name: "Home",
  components: {
    TrackHorizontalCard,
    HorizontalCardLoader,
  },

  data: () => {
    return {
      tracks: {},
      query: null,
    };
  },

  watch: {
    $route (to, from) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      this.query = params.q
    }
  },

  methods: {
    // hasHistory() {
    //   return window.history.length > 2;
    // },
    // goBack() {
    //   if (this.hasHistory()) {
    //     this.$router.go(-1);
    //   } else {
    //     this.$router.push({ name: "Home" });
    //   }
    // },
  },


  apollo: {
    tracks: {
      query: gql`
        query ($name_ar: String) {
          tracks (name_ar: $name_ar) {
            data {
              id
              name_ar
              name_en
              description_ar
              description_en
              duration_display
              thumbnail_url
              cover_url
              full_url

              authors {
                id
                name_ar
                name_en
              }

              categories {
                id
                name_ar
                name_en
              }
            }
          }
        }
      `,
      variables () {
        return {
          name_ar: this.query
        }
      }
    },
  },
};
</script>
